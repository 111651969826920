<template>
	<AppSubmenu class="layout-menu" :items="model" :menuMode="menuMode" :menuActive="active" :isSlimOrHorItemClick="isSlimOrHorItemClick" :root="true" :parentMenuItemActive="true" :mobileMenuActive="mobileMenuActive" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"/>
</template>

<script>
import AppSubmenu from './AppSubmenu';
import AuthService from './service/AuthService';

export default {
	name: "AppMenu",
	emits: ["menu-click", "menuitem-click", "root-menuitem-click"],
	props: {
		model: Array,
		menuMode: String,
		active: Boolean,
		mobileMenuActive: Boolean,
		isSlimOrHorItemClick: Boolean
	},
	components: {
		'AppSubmenu': AppSubmenu
	},
	methods: {
		onMenuClick(event) {
            this.$emit("menu-click", event);
        },
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event);
        },
		onRootMenuItemClick(event) {
			this.$emit("root-menuitem-click", event);
		},
		tokenGet() {
			AuthService.refreshtoken().then(data => {
					if (data.code === undefined || data.code != 200) {
						localStorage.removeItem('user');
						this.$router.push('/login?1');
						this.refreshInfo = false
					}
				});
		},
		tokenData () {
			this.refreshToken = setInterval(() => {
				if (this.$route.name == "logout" || this.$route.name == "login") {
					this.refreshInfo = false
				}
				if (this.refreshInfo) {
					this.tokenGet();
				}
			}, 120000)
		}
	},
	created() {
		this.tokenData();

	},
	mounted() {
		this.refreshInfo = true;
		this.tokenGet();
	},
	data() {
		return {
			refreshToken: null,
			refreshInfo: false,
		}
	}
}
</script>

<style scoped>

</style>