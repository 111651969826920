<template>
	<div class="pages-body login-page flex flex-column layout-topbar-green " style="background: url(/images/pages/login.svg);background-size: cover;">
    <div class="layout-topbar p-5 flex justify-content-between flex-row align-items-center">
		<div class="layout-topbar-left">
			<router-link class="layout-topbar-logo" to="/">
				<img id="logo" src="/admin/layout/images/gazdasagi.png" alt="Gazdasági Hivatal" style="height: 3.5rem" />
			</router-link>
		</div>
        <div class="layout-topbar-right">
            <div class="layout-topbar-actions-left">
            
            </div>
			<div class="layout-topbar-actions-right">
                <ul class="layout-topbar-items">
                    <li class="layout-topbar-item">
                        <router-link to="/">
                            <Button type="button" label="Főoldal" class="p-button-text p-button-plain topbar-button" style="color: white; font-weight: bold;"></Button>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-3 py-1">
                <h2>BEJELENTKEZÉS</h2>
            </div>

            <h4>Üdvözöljük</h4>

            <div class="pages-detail mb-6 px-6">Kérjük jelentkezzen be a Váci Egyházmegye Támogató rendszerébe</div>

            <form>
                <div class="input-panel flex flex-column px-3">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-envelope"></i>
                        </span>
                        <span class="p-float-label">
                            <InputText type="text" name="username" id="username" autocomplete="username" v-model="user.username" />
                            <label for="email">Email cím</label>
                        </span>
                    </div>

                    <div class="p-inputgroup mt-3 mb-3">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-lock"></i>
                        </span>
                        <span class="p-float-label">
                            <InputText type="password" name="password" id="password" autocomplete="password" v-model="user.password" />
                            <label for="password">Jelszó</label>
                        </span>
                    </div>

                    <div class="mb-3" v-if="message">
                        <InlineMessage severity="error" class="justify-content-center">{{ message }}</InlineMessage>
                    </div>                    

                </div>
                <Button class="login-button mb-3 mt-3 px-3" label="BEJELENTKEZÉS" @click="handleLogin()" :loading="loading" :disabled="loading"></Button>
            </form>
        </div>
        
    </div>
</div>

</template>

<script>
// import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";


export default {
        data() {
            const schema = yup.object().shape({
            username: yup.string().required("Username is required!"),
            password: yup.string().required("Password is required!"),
            });

            const user = {
                username: "",
                password: "",
            }    

            return {
                loading: false,
                message: "",
                schema,
                user,
            };
        },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    } else {
        let g = this.$router.currentRoute.value.query;
        if ('1' in g) {
            this.message = "Lejárt a session. Kérjük jelentkezzen be újra!"
        }
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.message = "";
      this.$store.dispatch("auth/login", this.user).then(
        (data) => {
            // console.log ("H1", data)
            if (data.message) {
                this.message = data.message;
            } else {
                this.$router.push("/");
            }
            this.loading = false;
        },
        (error) => {
            // console.log ("ERROR MESSAGE", error)
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.loading = false;
        }
      )
    },
   
  },

	}
</script>

<style scoped>
.p-button:enabled:hover {
    background: #2E7D32;
    color: #ffffff;
    border-color: transparent;
}
.p-button:enabled:active, .p-button:enabled:focus {
    background: #2E7D32;
}

.p-float-label input ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {
    top: -0.5rem !important;
    background-color: #ffffff;
    padding: 2px 4px;
    margin-left: -4px;
    margin-top: 0;
}


.pages-body.login-page .pages-panel .pages-header {
    background: #2E7D32;
}

</style>
