export default class VersionService {
    
    async getVersion() {
        const response = await fetch('/backend/version', {});        
        try {
            const data = await response.text();
            return data
        } catch(e) {
            const data = e
            return data
        }        
    }

}