<template>
	<div class="pages-body login-page flex flex-column layout-topbar-green " style="background: url(/images/pages/login.svg);background-size: cover;">
    <div class="layout-topbar p-5 flex justify-content-between flex-row align-items-center">
		<div class="layout-topbar-left">
			<router-link class="layout-topbar-logo" to="/">
				<img id="logo" src="/admin/layout/images/gazdasagi.png" alt="Gazdasági Hivatal" style="height: 3.5rem" />
			</router-link>
		</div>
        <div class="layout-topbar-right">
            <div class="layout-topbar-actions-left">
                
            </div>
			<div class="layout-topbar-actions-right">
                <ul class="layout-topbar-items">
                    <li class="layout-topbar-item">
                        <router-link to="/">
                            <Button type="button" label="Főoldal" class="p-button-text p-button-plain topbar-button" style="color: white; font-weight: bold;"></Button>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-3 py-1">
                <h2>Kijelentkezés</h2>
            </div>

            <h4>Kedves Felhasználó!</h4>

            <div class="pages-detail mb-6 px-6">Ön sikeresen kijelentkezett a rendszerből!</div>

        </div>
        
    </div>
</div>

</template>

<script>
// import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";


export default {
        data() {
            const schema = yup.object().shape({
            username: yup.string().required("Username is required!"),
            password: yup.string().required("Password is required!"),
            });

            const user = {
                username: "",
                password: "",
            }    

            return {
                loading: false,
                message: "",
                schema,
                user,
            };
        },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$store.dispatch("auth/login", this.user).then(
        () => {
          this.$router.push("/");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
   
  },

	}
</script>

<style scoped>
.p-button:enabled:hover {
    background: #2E7D32;
    color: #ffffff;
    border-color: transparent;
}
.p-button:enabled:active, .p-button:enabled:focus {
    background: #2E7D32;
}

.p-float-label input ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {
    top: -0.5rem !important;
    background-color: #ffffff;
    padding: 2px 4px;
    margin-left: -4px;
    margin-top: 0;
}


.pages-body.login-page .pages-panel .pages-header {
    background: #2E7D32;
}

</style>
