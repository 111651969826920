<template>
	<div class="layout-footer flex align-items-center p-4 shadow-2">
        &copy; Váci Egyházmegye - 2022 - Backend verzió: {{ versionBackend }} - Frontend verzió: {{ versionFrontend }}
    </div>
</template>

<script>
	import VersionService from './service/VersionService';

	export default {
		name: "AppFooter",
		props: {
			layoutMode: {
				type: String,
				default: 'light'
			}
		},
		computed: {
			isRTL() {
				return this.$appState.RTL;
			}
		},
		data() {
			return {
				versionBackend: "",
				versionFrontend: "3.0.4 (2024.09.05)",
				versionService: null,
			}
		},
		created() {
			this.versionService = new VersionService();
			this.versionService.getVersion().then(data => {
				this.versionBackend = data
			})
		}
	}
</script>

<style scoped>

</style>