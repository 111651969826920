<template>
    <Login v-if="$route.path === '/login'" />
    <Logout v-else-if="$route.path === '/logout'" />
    <App :topbarTheme="topbarTheme" :layoutMode="layoutMode" :menuTheme="menuTheme" @menu-theme="onMenuTheme" @topbar-theme="onTopbarThemeChange" @layout-mode-change="onLayoutChange" v-else />
</template>

<script>
    import App from './App.vue';
    import Login from './pages/Login';
	import Logout from './pages/Logout';

	export default {
        data() {
            return {
                theme: 'green',
                layoutMode: 'light',
                topbarTheme: 'lightgreen',
                menuTheme: 'green',
            }
        },
        methods: {
            onLayoutChange(layout) {
                this.layoutMode = layout;
                this.$appState.layoutMode = layout;
                this.menuTheme = layout;

                const logo = document.getElementById('logo');
                logo.src = 'layout/images/logo-light.svg';
                
                if (layout === 'dark') {
                    this.topbarTheme = 'dark';
                }
                else {
                    this.topbarTheme = 'blue';
                }
            },
            onTopbarThemeChange(theme) {
                this.topbarTheme = theme.name;

                const themeName = theme.name;
                const logo = document.getElementById('logo');

                if (themeName == 'white' || themeName == 'yellow' || themeName == 'amber'  || themeName == 'orange' || themeName == 'lime') {
                    logo.src = 'layout/images/logo-dark.svg';
                }
                else {
                    logo.src = 'layout/images/logo-light.svg';
                }
            },
            onMenuTheme(menuTheme) {
                this.menuTheme = menuTheme.name;
            }
        },
        components: {
            "App": App,
            "Login": Login,
			"Logout": Logout,
        }
	}
</script>

<style scoped>
</style>
