import authHeader from './AuthHeader';

class AuthImageService {

    async displayProtectedImage(imageId, imageUrl) {
        // console.log("displayProtectedImage", imageId, imageUrl)
        const response = await this.fetchWithAuthentication(imageUrl);
        const blob = await response.blob();
        if (response.ok && blob !== undefined) {
            // console.log ("BHERE", blob)
            const objectUrl = URL.createObjectURL(blob);
            const imageElement = document.getElementById(imageId);
            imageElement.src = objectUrl;
        }
    }

    fetchWithAuthentication(url) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        // console.log ("RQ", requestOptions)
        return fetch(url, requestOptions);
    }
}

export default new AuthImageService();