export default function authHeader() {
    let user
    
    try {
      user =  JSON.parse(localStorage.getItem('user'));
    } catch {
      user = null
    }
  
    if (user && user.token) {
      return { Authorization: 'Bearer ' + user.token };
    } else {
      return {};
    }
  }